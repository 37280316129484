import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { SnackbarProvider } from 'context/Snackbar/SnackbarContext';
import { QueryParamProvider } from 'use-query-params'
import { MAIN } from './constants/appRoutes';
import Main from "./projectComponent/Main";
import TaSnackbar from 'components/snackbar/TaSnackbar';

const App = () => {
  return (
    <Router>
      <SnackbarProvider>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path={MAIN} component={Main} />
          </Switch>
          <TaSnackbar />
        </QueryParamProvider>
      </SnackbarProvider>
    </Router>

  );
}

export default App;
