import { useState, useEffect } from "react"
import { Grid } from '@mui/material'
import { useQueryParam, StringParam } from 'use-query-params';
import Loader from "components/loader/Loader";
import { fnAppName } from "functions/AppName";
import TaTypography from "components/typography/TaTypography";
import TaPaper from "components/paper/TaPaper";
import Quickbook from "../Quickbook/";

let appName;

const initialModel = {
    nameApp: "",
    integration: "",
    code: "",
    token: "",
}

const Main = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [statusCheck, setStatucCheck] = useState(-1);
    const [appInfo, setAppInfo] = useState(initialModel);
    const [code] = useQueryParam('code', StringParam);
    const [quickbookState] = useQueryParam('state', StringParam);
    const [realmId] = useQueryParam('realmId', StringParam);

    useEffect(() => {
        if (appInfo.integration !== "" && appInfo.nameApp === "") {
            try {
                setIsLoading(true)
                switch (appInfo.integration.toLowerCase()) {
                    case 'quickbook':
                        appName = fnAppName(atob(quickbookState).split('|')[0])
                        setAppInfo(preVal => ({
                            ...preVal,
                            nameApp: appName,
                            code: code,
                            token: quickbookState,
                        }))
                        break;
                    default:
                        setStatucCheck(2)
                }
                setStatucCheck(appName !== "" ? 1 : 2)
            } catch (error) {
                console.error('Error no controlado, uso no apropiado de la plataforma => ', error)
                setStatucCheck(1)
            }
            setIsLoading(false)
        }
    }, [appInfo])

    useEffect(() => {
        if (code && quickbookState && realmId) {
            setStatucCheck(0)
            setAppInfo(preVal => ({...preVal, integration: "quickbook"}))
        }
    }, [])

    return (
        <TaPaper
            centerDiv={true}
            squareBorder={true}
            Content={
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                        <img src="https://totalaplicaciones.com/img/totalaplicaciones/TotalAppLogo.png" alt="TotalAplicaciones" width="500px"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} hidden={statusCheck !== 0}>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                                <TaTypography>Verificando...</TaTypography>
                            </Grid>
                            <Loader isLoading={isLoading} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} hidden={statusCheck !== 1}>
                       {
                        appInfo.integration === 'quickbook' && <Quickbook appInfo={appInfo}/>
                       }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} hidden={statusCheck !== 2}>
                        <TaTypography>Proceso de verificación no valido</TaTypography>
                    </Grid>
                </Grid>
            }
        />

    );
};

export default Main;