import { memo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from "@mui/material/"

const TaTypography = memo(({ fontFamily, fontSize, type, color,
  text, align, width, height,
  display, isTitle, noWrap, children,
  onClick, id, }) => (<Typography
    id={id}
    onClick={(onClick) && onClick}
    noWrap={noWrap && noWrap}
    align={(isTitle) ? 'center' : align}
    sx={{
      fontFamily: fontFamily,
      margin: "2px",
      fontSize: (isTitle) ? "18pt" : fontSize,
      color: color ?? 'var(--main-text-color0)',
      height: height,
      width: width,
      lineHeight: "1.0",
    }}
    display={display}>
    {(!children) ? text : children}
  </Typography>))

TaTypography.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  text: PropTypes.any,
  display: PropTypes.string,
  fontFamily: PropTypes.string,
  id: PropTypes.string
}

TaTypography.defaultProps = {
  fontSize: "12pt",
  type: "regular",
  color: 'var(--main-text-color0)',
  align: "left",
  display: "initial",
  fontFamily: "Helvetica",
  id: '',
  isTitle: false
}

export default TaTypography