import axios from 'axios';
import { saveAs } from 'file-saver'

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_API_URL : process.env.REACT_APP_DEVELOPMENT_API_URL,
    authBasicHeader = {
        username: "",
        password: "",
    },
    timeout = 60000;

let basicInfo;

export const setBaseUrl = (value) => baseUrl = value
export const setBasicAuth = (user, pass = "") => {
    authBasicHeader = {
        username: user,
        password: pass
    }
}
export const fnGetUrl = async (apiRequest, lang = 'ES') => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    if (apiRequest.useBasic) {
        return await axios.get(urlEndpoint, {
            auth: {
                ...authBasicHeader
            },
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error, lang))
    } else {
        return await axios.get(urlEndpoint, {
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout,
        })
            .then(response => response)
            .catch(error => handleError(error, lang))
    }

}
export const fnPostUrl = async (apiRequest, lang) => {
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    let contentType = apiRequest.contentType ?? 'application/json'
    let axiosOptions = {
        method: 'post',
        url: urlEndpoint,
        headers: {
            'Content-Type': contentType
        },
        timeout: apiRequest.timeout ?? timeout

    }
    if (apiRequest.useBasic) {
        basicInfo = apiRequest.basicAuth ?? authBasicHeader
        axiosOptions = {
            ...axiosOptions,
            headers: {
                ...axiosOptions.headers,
                'Authorization': `Basic ${btoa(`${basicInfo.username}:${basicInfo.password}`)}`
            }
        }
    }
    if (contentType === 'application/json') {
        axiosOptions = {
            ...axiosOptions,
            data: apiRequest.body
        }
    }else if(contentType === 'application/x-www-form-urlencoded'){
        axiosOptions = {
            ...axiosOptions,
            params: apiRequest.body
        }
    }
    return axios(axiosOptions).then(response => response)
        .catch(error => handleError(error, lang))
}
export const fnPutUrl = async (apiRequest) => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    if (apiRequest.useBasic) {
        return await axios.put(urlEndpoint, apiRequest.body, {
            auth: {
                ...authBasicHeader
            },
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error))
    } else {
        return await axios.put(urlEndpoint, apiRequest.body, {
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error))
    }
}

const handleError = (error, lang = 'ES') => {
    if (error.message.toString() === "Network Error") {
        return {
            status: 504,
            data: {
                message: langResponse(lang, 504),
            }
        }
    }
    if (error.response) {
        return error.response
    } else {
        if (error.message.toString().includes('timeout')) {
            return {
                status: 408,
                data: {
                    message: langResponse(lang, 408),
                }
            }
        }
        return {
            status: 503,
            data: {
                message: langResponse(lang, 503)
            }
        }
    }
}

const langResponse = (lang, status) => 'No hay conexión contra el servicio, por favor reintente nuevamente'

const newUrl = (baseUrl, url) => (url) ? url : baseUrl

export const fnGetBaseUrl = () => baseUrl

export const fnDownloadFile = (url, name) => {
    try {
        const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
        const isIOs = ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        if (isIOs === false) saveAs(`${baseUrl}${url}`, name)
    } catch (error) { console.error('fnDownloadFile => ', error) }
}