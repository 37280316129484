import { memo } from "react";
import PropTypes from 'prop-types'

//MUI
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

const buttonStyles = makeStyles({
    buttonRoot: {
        // width: props => props.miniButton ? '30px' : undefined,
        height: props => props.miniButton ? '30px' : undefined,
        minHeight: props => props.miniButton ? '30px' : undefined,
        fontSize: props => props.miniButton ? '8pt' : props.fontSize ? props.fontSize : '12pt',
        margin: props => props.topMargin ? '5px' : '5px',
        // minWidth: props => props.miniButton ? '120px' : '125px',
        background: props => props.background ?? 'var(--main-bg-color7)',
        color: 'white',
        '&:hover': {
            background: props => props.color ? props.color : 'var(--main-bg-color3)',
        },
        borderRadius: props => props.borderRadius ?? '5px',
        fontFamily: 'Helvetica',
    }
})

const TaButtonColor = (color) => {
    if (color === 'orange') {
      return "var(--main-bg-color7)"
    } else if (color === 'red') {
      return "red"
    } else if (color === 'green') {
      return "var(--main-bg-color5)"
    } else if (color === 'transparent') {
      return "transparent"
    } else if (color === 'white') {
      return "var(--main-bg-color0)"
    } else {
      return "var(--main-bg-color4)"
    }
  }

  
  const TaButton = memo (({
      id, label, disabled, onClick,
      size, variant, topMargin, index,
      uniqueKey, color, fontSize, type, href,
      target, minWidth, background, borderRadius, miniButton = false
    }) => {
    const buttonColor = TaButtonColor(color)
    const fnOnClick = () => (onClick) && onClick(id, uniqueKey)
    const buttonClasses = buttonStyles({ topMargin, minWidth, color: buttonColor, fontSize, background, borderRadius, miniButton })
    return (
        <Button
            id={id}
            variant={variant}
            disabled={disabled}
            onClick={fnOnClick}
            size={size}
            classes={{ contained: buttonClasses.buttonRoot}}
            data-id={index}
            type={type ?? type}
            href={href}
            target={target}>
            {label}
        </Button>
    )
})

TaButton.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    size: PropTypes.string,
    loading: PropTypes.bool,
    topMargin: PropTypes.bool,
    minWidth: PropTypes.bool,
    setRef: PropTypes.func,
    hidden: PropTypes.bool,
    hotkey: PropTypes.string,
}

TaButton.defaultProps = {
    disabled: false,
    variant: 'contained',
    size: 'medium',
    color: 'orange',
    loading: false,
    topMargin: true,
    minWidth: true,
    hidden: false,
    hotkey: '',
    onClick: () => { }, 
}

export default TaButton