import { useState, useContext } from 'react';
import { Grid } from '@mui/material'
import TaTypography from 'components/typography/TaTypography';
import TaButton from 'components/button/TaButton';
import Loader from 'components/loader/Loader';
import { postAuth } from 'services/GlobalService';
import { SnackbarContext } from 'context/Snackbar/SnackbarContext';

const Quickbook = ({ appInfo }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const { sbDispatch } = useContext(SnackbarContext);

    const fnPostAth = async () => {
        setIsLoading(true);
        try {
            const resp = await postAuth({
                integrator: appInfo.integration,
                code: appInfo.code,
                token: appInfo.token,
            })
            if (resp.status === 200 && resp.data.errorCode === 0) {
                setIsReady(true)
            } else {
                sbDispatch.error(resp.data.message)
            }
        } catch (error) {

        }
        setIsLoading(false);
    }

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            {
                !isReady
                    ? <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                            <TaTypography>{`El aplicativo: ${appInfo.nameApp} requiere autorización para QuickBook`}</TaTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                            <TaButton onClick={fnPostAth} label="Autorizar" disabled={isLoading}/>
                        </Grid>
                    </>
                    : <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                            <TaTypography>{`El aplicativo: ${appInfo.nameApp} ha sido autorizado exitosamente, por favor darle a verificar en el aplicativo para completar el proceso`}</TaTypography>
                        </Grid>
                    </>
            }
            <Loader isLoading={isLoading} />
        </Grid>
    );
};

export default Quickbook;